import React from 'react'
import { useRef } from 'react'
import  emailjs from '@emailjs/browser'
import './Join.css'
const Join = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9rdhbda', 'template_5san08j', form.current, '4_8bsXeSi4LpMiJW0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className='join' id='join'>
      <div className='left-j'>
        <hr />
        <div>
          <span className='stroke-text'>READY TO</span>
          <span> LEVEL UP</span>
        </div>

        <div>
          <span>YOUR BODY</span>
          <span className='stroke-text'> WITH US?</span>
        </div>

      </div>
      <div className='right-j'>
        <form ref={form} className='email-container' onSubmit={sendEmail} >
          <input type='email' name='user_emai' placeholder='please enter your email address' />
          <button className='btn btn-j'>Join Now</button>
        </form>
      </div>
    </div>
  )
}

export default Join